import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../views/NotFound.vue'


Vue.use(VueRouter)



const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: '38 Wall St Event Center'
    },
    component: () =>
      import("../views/index.vue"),
  },

  {
    path: '*',
    name: NotFound,
    meta: {
      title: '404'
    },
    component: ()=> import("../views/NotFound.vue")
  }
];
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to,savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash, }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router;
