<template>
  <div class="tokyo_tm_contact">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Thanks</span>
          <h3>We'll Be in Touch</h3>
        </div>
      </div>
    </div>
    <!-- End Title -->

    <div class="description">
      <p>
Your message is on its way. Hopefully the carrier pigeon will make it.
</p><p>
We'll get back to you as soon as possible. If it is urgent, feel free to text us at 269-605-4408.
</p><p>
Thanks!
</p>
    </div>
  </div>
</template>

<script>
  //import { ValidationObserver } from "vee-validate";
  //import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  export default {
    components: {
      //ValidationObserver,
      //ValidationProvider,
    },
    data() {
      return {
        formData: {
          name: "",
          email: "",
          message: "",
        },
      };
    },
    methods: {
      onSubmit() {
        //console.log(this.formData);
        return true;
      },
    },
  };
</script>
