<template>
  <div class="tokyo_tm_contact">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Contact</span>
          <h3>Get in Touch</h3>
        </div>
      </div>
    </div>
    <!-- End Title -->

    <div class="map_wrap">
      <iframe
        id="gmap_canvas"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5919.317322383903!2d-86.45942557227909!3d42.114780699102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8810c663776b62f3%3A0x1cdbffb7e15f082!2s38%20W%20Wall%20St%2C%20Benton%20Harbor%2C%20MI%2049022!5e0!3m2!1sen!2sus!4v1680274786256!5m2!1sen!2sus"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe>
    </div>
    <!-- End Google Map -->

    <div class="fields">
        <form
          action="https://formsubmit.co/d87c163f655dbc4e6b5f8f61b4c9d9a5"
          method="post"
          class="contact_form"
        >
          <input type="hidden" name="_subject" value="38WallSt.com Form Submission">
          <div class="first">
            <ul>
                <li>
                  <input
                    name="name"
                    type="text"
                    placeholder="Your Name"
                  />
                </li>

                <li>
                  <input
                    name="email"
                    type="text"
                    rules="required|email"
                    placeholder="Your Email Address"
                  />
                </li>
                <li>
                  <textarea
                    name="message"
                    placeholder="I'd like more info on ..."
                  ></textarea>
                </li>
            </ul>
          </div>
          <div class="tokyo_tm_button">
            <button type="submit" class="ib-button">Send Message</button>
          </div>
        </form>
    </div>
    <!-- END FIELDS -->
  </div>
</template>

<script>
  //import { ValidationObserver } from "vee-validate";
  //import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  export default {
    components: {
      //ValidationObserver,
      //ValidationProvider,
    },
    data() {
      return {
        formData: {
          name: "",
          email: "",
          message: "",
        },
      };
    },
    methods: {
      onSubmit() {
        //console.log(this.formData);
        return true;
      },
    },
  };
</script>
