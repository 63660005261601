<template>
  <div class="tokyo_tm_portfolio">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Event Ideas</span>
          <h3>Past Events</h3>
        </div>
        <!-- End Left -->
      </div>
    </div>
    <!-- End tokyo_tm_title -->

    <div>
      <ul class="portfolio_list">
        <CoolLightBox :items="allItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in allItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activetab: 1,
        allItems: [
          {
            src:   require("../assets/img/portfolio/1.jpg"),
            thumb: require("../assets/img/portfolio/1.jpg"),
          },
          {
            src:   require("../assets/img/portfolio/2.jpg"),
            thumb: require("../assets/img/portfolio/2.jpg"),
          },
          {
            src:   require("../assets/img/portfolio/3.jpg"),
            thumb: require("../assets/img/portfolio/3.jpg"),
          },
          {
            src:   require("../assets/img/portfolio/4.jpg"),
            thumb: require("../assets/img/portfolio/4.jpg"),
          },
          {
            src:   require("../assets/img/portfolio/5.jpg"),
            thumb: require("../assets/img/portfolio/5.jpg"),
          },
        ],
        index: null,
      };
    },
  };
</script>

<style lang="scss" scoped></style>
